import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import useFetchData from "../../Hooks/useFetchData";
import useTableControls from "../../Hooks/useTableControls";
import { getFeedbacks } from "../../Services/Feedback";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function Feedbacks() {
    // const sampleData = [
    //   {
    //     sn: "1",
    //     fullName: "Mark Carson",
    //     userType: "User",
    //     datePosted: "01/02/2022",
    //     email: "abc@xyz.com",
    //   },
    //   {
    //     sn: "2",
    //     fullName: "Mark Carson",
    //     userType: "User",
    //     datePosted: "01/02/2022",
    //     email: "abc@xyz.com",
    //   },
    //   {
    //     sn: "3",
    //     fullName: "Mark Carson",
    //     userType: "User",
    //     datePosted: "01/02/2022",
    //     email: "abc@xyz.com",
    //   },
    //   {
    //     sn: "4",
    //     fullName: "Mark Carson",
    //     userType: "User",
    //     datePosted: "01/02/2022",
    //     email: "abc@xyz.com",
    //   },
    // ];

    const {
        perPage,
        setPerPage,
        status,
        setStatus,
        search_string,
        setSearchString,
        from,
        setFrom,
        to,
        setTo,
    } = useTableControls();

    const {
        // INTERNAL EXPORTS
        setPage,
        // REACT QUERY EXPORTS
        isFetching,
        isLoading,
        data,
        refetch,
    } = useFetchData("feedback_logs", getFeedbacks, [
        perPage,
        search_string,
        from,
        to,
    ]);
    // console.log("feedback_logs Data: ", data?.data);

    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    {/* Basic form layout section start */}
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show dashCard pt-5 px-5">
                                    <Table
                                        key={"feedbacks-table"}
                                        showTableName
                                        tableName={"Feedbacks"}
                                        tableTitles={[
                                            "S.No.",
                                            "Full Name",
                                            "User Type",
                                            "Subject",
                                            "Date Submitted",
                                            "Action",
                                        ]}
                                        dateFilter
                                        showEntries
                                        // sortBy
                                        isLoading={isLoading}
                                        isFetching={isFetching}
                                        data={data?.data?.data?.docs}
                                        totalPages={data?.data?.data?.totalPages}
                                        totalDocs={data?.data?.data?.totalDocs}
                                        setPage={setPage}
                                        setSearchString={setSearchString}
                                        status={status}
                                        setStatus={setStatus}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                        from={from}
                                        setFrom={setFrom}
                                        to={to}
                                        setTo={setTo}
                                    >
                                        {!isFetching &&
                                            data?.data?.data?.docs?.map((item, index) => (
                                                <tr>
                                                    <td>{getSerialNumber(data?.data?.data, index)}</td>
                                                    <td>{item?.user?.firstName}</td>
                                                    <td>{item?.user?.role}</td>
                                                    <td>{item?.subject}</td>
                                                    <td>
                                                        {item?.createdAt &&
                                                            format_date(item?.createdAt, "MM/DD/YYYY")}
                                                    </td>
                                                    {/* <td>{item?.email}</td> */}
                                                    <td>
                                                        <StatusHandler
                                                            key={`${item?._id}-status`}
                                                            view
                                                            item={item}
                                                            redirectUrl={`/feedbacks/feedback-details/${item?._id}`}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
