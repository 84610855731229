import { useRef } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Confirmation, Error } from "../Components/Modal";
import { useMutation, useQuery } from "react-query";
import { logout } from "../Services/Auth";
import { getNotifications } from "../Services/Notification";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Nav from 'react-bootstrap/Nav';

export default function Sidebar() {
  const navigate = useNavigate();
  const isActive = useRef(null);

  const { user } = useSelector((state) => state.slice);

  const navLinks = [
    {
      title: "Dashboard",
      to: "/dashboard",
      icon: "fas fa-th-large",
    },
    {
      title: "User Management",
      to: "/user-management",
      icon: "fas fa-cog",
    },
    {
      title: "Muvrrs Management",
      to: "/mover-management",
      icon: "fas fa-truck",
    },
    {
      title: "Rides Management",
      to: "/ride-management",
      icon: "fas fa-truck-moving",
    },
    {
      title: "Earnings",
      to: "/earnings",
      icon: "fas fa-money-bill-wave",
    },
    {
      title: "Feedbacks",
      to: "/feedbacks",
      icon: "fas fa-comment-alt",
    },
    // {
    //   title: "Business Management",
    //   to: "/business-management",
    //   icon: "fas fa-briefcase",
    // },
    {
      title: "Vehicle Management",
      to: "/vehicle-management",
      icon: "fas fa-car",
    },
    {
      title: "Vehicle Type Management",
      to: "/vehicle-type-management",
      icon: "fas fa-car-alt",
    },
    {
      title: "Settings",
      to: "/settings",
      icon: "fas fa-cog",
    },
    {
      title: "Payment Logs",
      to: "/payment-logs",
      icon: "fas fas fa-money-check-alt",
    },
    {
      title: "Reports",
      to: "/reports",
      icon: "fas fa-comment-alt",
    },
  ];

  const { mutate, isLoading } = useMutation(() => logout(), {
    retry: false,
    onSuccess: (res) => {
      // console.log("logout Res: ", res.data);
      localStorage.clear();
      navigate("/");
    },
    onError: (err) => {
      // console.log("logout Err: ", err);
      Error(err.response.data?.message);
    },
  });

  const {
    isLoading: notificationLoading,
    data: notificationData,
    refetch,
  } = useQuery(["getNotifications"], () => getNotifications(1, 5));
  // console.log("getNotifications Data", notificationData?.data);

  return (
    <>
      <nav className="header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-light navbar-border">
        <div className="navbar-wrapper">
          <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mobile-menu d-md-none mr-auto">
                <a
                  className="nav-link nav-menu-main menu-toggle hidden-xs"
                  href="#"
                >
                  <i className="ft-menu font-large-1" />
                </a>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="navbar-brand" to="/dashboard">
                  {" "}
                  <img
                    className="brand-logo img-fluid"
                    alt="stack admin logo"
                    src="images/logo.png"
                  />{" "}
                </Link>{" "}
              </li>
              <li className="nav-item d-md-none">
                {" "}
                <a
                  className="nav-link open-navbar-container"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-mobile"
                >
                  <i className="fa fa-ellipsis-v" />
                </a>{" "}
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav me-auto float-start"></ul>
              <ul className="nav navbar-nav float-end nav-right align-items-center">
                <li className="dropdown dropdown-notification nav-item">
                  <button
                    className="notBtn nav-link nav-link-label"
                    onClick={() => navigate("/chat")}
                  >
                    <i className="fas fa-comment-dots" />
                  </button>
                  {/* <button
                    className="notBtn nav-link nav-link-label"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fas fa-comment-dots" />
                    <span className="badge badge-pill badge-default badge-accent badge-default badge-up">
                      1
                    </span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right notificationDiv">
                    <li
                      className="scrollable-container media-list ps-container ps-theme-dark ps-active-y"
                      data-ps-id="a385dd14-315a-f80c-bd87-398bda7b376e"
                    >
                      <a href="notifications.php">
                        <div className="media">
                          <div className="media-left">
                            <div className="noti-img">
                              <img
                                src="images/userImage.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <h6 class="media-heading">
                              You have new notification!
                            </h6>
                            <p className="notification-text">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Aenean euismod bibendum laoreet. Proin
                              gravida dolor sit amet lacus accumsan et viverra
                              justo commodo. Proin sodales pulvinar sic tempor.{" "}
                            </p>
                            <div className="notificationBelowInfo">
                              <small>
                                <time
                                  className="media-meta"
                                  dateTime="2015-06-11T18:29:20+08:00"
                                >
                                  5 secs ago
                                </time>
                              </small>
                            </div>
                          </div>
                        </div>
                      </a>
                      <div
                        className="ps-scrollbar-x-rail"
                        style={{ left: 0, bottom: 3 }}
                      >
                        <div
                          className="ps-scrollbar-x"
                          tabIndex={0}
                          style={{ left: 0, width: 0 }}
                        />
                      </div>
                      <div
                        className="ps-scrollbar-y-rail"
                        style={{ top: 0, height: 255, right: 0 }}
                      >
                        <div
                          className="ps-scrollbar-y"
                          tabIndex={0}
                          style={{ top: 0, height: 132 }}
                        />
                      </div>
                    </li>
                    <li className="dropdown-menu-footer">
                      <Link
                        className="dropdown-item notification text-right text-underline"
                        // href="notifications.php"
                        to="/chat"
                      >
                        View All
                      </Link>
                    </li>
                  </ul> */}
                </li>
                <li className="dropdown dropdown-notification nav-item">
                  <button
                    className="notBtn nav-link nav-link-label"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fas fa-bell" />{" "}
                    <span className="badge badge-pill badge-default badge-danger badge-default badge-up">
                      {notificationData?.data?.data?.totalDocs &&
                        notificationData?.data?.data?.totalDocs}
                    </span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-right notificationDiv">
                    {/* <li class="dropdown-menu-header">
									<h6 class="dropdown-header m-0" usersfuse>
										<span class="grey darken-2">Notifications</span> <span class="notification-tag badge badge-default badge-danger float-right m-0">5 New</span>
									</h6>
								</li> */}
                    <li className="dropdown-menu-header d-flex align-items-baseline justify-content-between">
                      <h6>Notifications</h6>
                      {notificationData?.data?.data?.docs?.length > 0 && (
                        <Link to="/notifications" className="accentColor">
                          View All
                        </Link>
                      )}
                    </li>
                    <li className="scrollable-container media-list ps-container ps-theme-dark">
                      {notificationData?.data?.data?.docs?.length > 0 ? (
                        notificationData?.data?.data?.docs?.map((item) => (
                          <Link to="/notifications">
                            <div className="media d-flex">
                              <div className="media-left flex-shrink-0 align-self-top">
                                <i className="fas fa-bell" />
                              </div>
                              <div className="media-body flex-grow-1">
                                <h6 className="media-heading">
                                  {item?.notification?.title}
                                </h6>
                                <small>
                                  <time
                                    className="media-meta"
                                  // dateTime="2015-06-11T18:29:20+08:00"
                                  >
                                    {moment(item?.createdAt).fromNow()}
                                  </time>
                                </small>
                              </div>
                            </div>
                          </Link>
                        ))
                      ) : (
                        <Link>
                          <div className="media d-flex">
                            <div className="media-body flex-grow-1">
                              <h6 className="media-heading">
                                No new notification
                              </h6>
                            </div>
                          </div>
                        </Link>
                      )}
                    </li>
                    {/* <li class="dropdown-menu-footer">
									<a class="dropdown-item text-center" href="notifications.php">View All Notifications</a>
								</li> */}
                  </ul>
                </li>
                <li className="dropdown dropdown-user nav-item">
                  <a
                    className="dropdown-toggle nav-link dropdown-user-link"
                    // href="#"
                    data-bs-toggle="dropdown"
                  >
                    <span className="avatar avatar-online">
                      {" "}
                      <img
                        src={
                          user?.profileImage?.imageUrl ??
                          "images/avatar.png"
                        }
                        alt="avatar"
                      />{" "}
                    </span>
                    <div className="user-details">
                      <div className="userName">
                        Hi, {user?.firstName ?? "Mark"}
                      </div>
                      <div className="userRole">Admin</div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      className="dropdown-item"
                      // href="../header-pages/vehicle-type-management.php"
                      to="/vehicle-type-management"
                    >
                      <i className="fa fa-truck" />
                      Vehicle Type Management
                    </Link>
                    <Link
                      className="dropdown-item"
                      // href="../header-pages/profile.php"
                      to="/profile"
                    >
                      <i className="fa fa-user" />
                      Profile
                    </Link>
                    <Link
                      className="dropdown-item"
                      // href="../header-pages/payment-logs.php"
                      to="/payment-logs"
                    >
                      <i className="fas fa-file-alt" />
                      Payment Logs
                    </Link>
                    <Link
                      className="dropdown-item"
                      // href="../header-pages/settings.php"
                      to="/settings"
                    >
                      <i className="fa fa-th-large" />
                      Settings
                    </Link>
                    <button
                      type="button"
                      // data-bs-toggle="modal"
                      // data-bs-target="#logout"
                      className="dropdown-item notBtn"
                      onClick={() =>
                        Confirmation(
                          `Are you sure you want to logout from this platform`,
                          "Yes",
                          () => mutate()
                        )
                      }
                    >
                      <i className="fas fa-sign-out-alt" />
                      Sign Out
                    </button>
                  </div>
                </li>
                {/* <li class="nav-item d-none d-md-block">
							<a class="nav-link nav-menu-main menu-toggle hidden-xs is-active" href="#"><i class="ft-menu"></i></a>
						</li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/*menu start here*/}
      <div
        className="main-menu menu-fixed menu-light menu-accordion"
        data-scroll-to-active="true"
      >
        <div className="main-menu-content">
          <ul
            className="navigation"
            id="main-menu-navigation"
            data-menu="menu-navigation"
          >
            {navLinks?.map((item, index) => (
              <Nav.Item as="li" key={item.to}>
                <NavLink to={item.to}>
                  <i className={item.icon} />
                  <span className="menu-title" data-i18n="">{item.title}</span>
                </NavLink>
              </Nav.Item>

              // <li
              //   className={`nav-item${isActive.current === index ? " active" : ""
              //     }`}
              // >
              //   <Link
              //     to={item.to}
              //     onMouseOver={() => (isActive.current = index)}
              //   >
              //     <i className={item.icon} />
              //     <span className="menu-title">{item.title}</span>
              //   </Link>
              // </li>
            ))}
            {/* <li className="nav-item">
              <a href="../dashboard/dashboard.php">
                <i className="fas fa-th-large" />
                <span className="menu-title">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="../users/users-management.php">
                <i className="fas fa-cog" />
                <span className="menu-title">User Management</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="../mover/mover-management.php">
                <i className="fas fa-truck" />
                <span className="menu-title">Mover Management</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="../rides/rides-management.php">
                <i className="fas fa-truck-moving" />
                <span className="menu-title">Riders Management</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="../earnings/earnings.php">
                <i className="fas fa-money-bill-wave" />
                <span className="menu-title">Earnings</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="../feedbacks/feedbacks.php">
                <i className="fas fa-comment-alt" />
                <span className="menu-title">Feedbacks</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      {/*menu end here*/}
    </>
  );
}
