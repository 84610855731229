export let connection_string;
export let image_url;
export let socket_url;

const PORT = 16410;

if (window.location.hostname == "localhost") {
  // connection_string = http://localhost:${PORT}/apis;
  // image_url = http://localhost:${PORT};
  // socket_url = http://localhost:${PORT};
  // connection_string = `http://localhost:16410/apis`;
  // connection_string = `https://custom-dev.onlinetestingserver.com:${PORT}/apis`;
  connection_string = `https://muvrrs.org:5000/apis`;
  // image_url = https://custom-dev.onlinetestingserver.com:${PORT};
  // socket_url = https://custom-dev.onlinetestingserver.com:${PORT};
  image_url = `https://muvrrs.org:5000`;
  socket_url = `https://muvrrs.org:5000`;
} else {
  // connection_string = `http://localhost:16410/apis`;

  // connection_string = `https://custom-dev.onlinetestingserver.com:${PORT}/apis`;
  connection_string = `https://muvrrs.org:5000/apis`;
  // image_url = https://custom-dev.onlinetestingserver.com:${PORT};
  // socket_url = https://custom-dev.onlinetestingserver.com:${PORT};
  image_url = `https://muvrrs.org:5000`;
  socket_url = `https://muvrrs.org:5000`;
}